
import BarChartIcon from '@material-ui/icons/BarChart';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';

export default [
  {
    items: [
      {
        title: 'Equipos',
        href: '/Productos',
        icon: HomeIcon,
        items: [
          {
            title: 'Lista de Equipos',
            href: '/products'
          },
          {
            title: 'Tipos de Equipos',
            href: '/equipment'
          }
        ]
      }
    ]
  },
];
